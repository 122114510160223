body {
  font-family: sans-serif;
}

h3 {
  margin-bottom: 0;
}

main.main-page {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

main.main-page > div.main-header {
  width: 100%;
  background-color: #add8e6;
  justify-content: center;
  font-family: cursive;
  display: flex;
}

ul.event-list {
  padding: 0;
  list-style: none;
}

ul.event-list > li.event {
  background-color: #f5f5ff;
  border-radius: 4px;
  margin: 2rem;
  padding: .5rem;
  box-shadow: 0 4px 8px #0003;
}

ul.event-list > li.event > a {
  color: inherit;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  display: flex;
}

ul.event-list > li.event > p {
  text-align: justify;
}

/*# sourceMappingURL=index.69fbe2d6.css.map */
