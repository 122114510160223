body {
  font-family: sans-serif;
}

h3 {
  margin-bottom: 0;
}

main.main-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

main.main-page > div.main-header {
  display: flex;
  background-color: lightblue;
  width: 100%;
  justify-content: center;
  font-family: cursive;
}

ul.event-list {
  list-style: none;
  padding: 0;
}

ul.event-list > li.event {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 2rem;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: #f5f5ff;
}

ul.event-list > li.event > a {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
}

ul.event-list > li.event > p {
  text-align: justify;
}
